.project-description {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  .description-final {
    width: 50%;
    height: 100%;
    padding: 20px;
    background-color: rgba(255, 222, 95, 0.45);
    p {
      font-weight: 400;
      font-size: 18px;
      text-align: justify;
    }
  }
  .image-container-description {
    width: 50%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      max-height: 600px;
      object-fit: contain;
    }
  }
}
@media screen and (max-width: 576px) {
  .project-description {
    flex-direction: column;

    justify-content: center;
    align-items: center;
    .description-final {
      padding: 2%;
      width: 95%;
      p {
        font-size: 22px;
      }
    }
    .image-container-description {
      width: 80%;
    }
  }
}
