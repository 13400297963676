body,
html {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: Arial, Helvetica, sans-serif;
}

.container-page {
  position: relative;
  overflow: hidden;
  width: 100%;
  display: flex;
  justify-content: center;
  .container-responsive {
    max-width: 1200px;
    width: 100%;
  }
}

.background {
  position: fixed; /* Fijar la imagen de fondo */
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: -1;
}

.background img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.section {
  position: relative;
  z-index: 1;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: white;
  font-size: 2em;
  margin: 0;
  background: rgba(0, 0, 0, 0.5);
}
