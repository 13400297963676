// Description.scss
.description-container {
  display: flex;
  flex-direction: column;
  .yellow-line {
    width: 100%;
    height: 50px;
    background-color: #ffde5f;
  }

  .container {
    display: flex;
    background-color: #dd6a31;
    .instagram-container {
      display: flex;
    }
    h1 {
      font-size: 2em;
      margin: 0;
      color: white; // Ajustar el color del texto para mejorar la visibilidad
    }

    p {
      margin: 5px 0;
      color: white; // Ajustar el color del texto para mejorar la visibilidad
    }

    a img {
      width: 30px;
      height: 30px;
      margin-right: 10px;
    }
    .left-column {
      width: 50%;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      padding: 5rem 0rem 5rem 0rem;
    }
    .right-column {
      display: flex;
      width: 50%;
      padding: 3rem 3rem 3rem 0rem;
      flex-direction: row;
      .text-container {
        width: 50%;
        height: 100%;
        .text-description {
          text-align: center;
          font-size: 16px;
          font-weight: 800;
          line-height: 28.8px;
          margin: 15px;
        }
      }
      .image-container {
        width: 50%;
        height: 100%;
        img {
          width: 100%;
          object-fit: contain;
        }
      }
    }
  }
}
@media screen and (max-width: 992px) {
  .description-container {
    .container {
      flex-direction: column;
      .left-column {
        width: 100%;
      }
      .right-column {
        width: 100%;
        display: flex;
        justify-content: center;
        .image-container {
          width: 40%;
          img {
            max-height: 400px;
            object-fit: contain;
          }
        }
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .description-container {
    .container {
      .left-column {
        img {
          width: 100%;
          object-fit: contain;
        }
      }
      .right-column {
        flex-direction: column;
        padding: 0px;
        .text-container {
          width: 100%;
        }
        .image-container {
          width: 100%;
        }
      }
    }
  }
}
