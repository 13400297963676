.container-gallery {
  .container-title {
    background-color: rgba(254, 191, 84, 0.5);
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    padding: 20px;
    h2 {
      font-size: 56px;
      font-weight: bold;
      color: #542400;
    }
  }
  .container-gallery-images {
    background-color: rgba(255, 192, 88, 0.8);
  }
  .container-text {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    .container-text-bg {
      width: 80%;
      background-color: rgba(255, 222, 95, 0.8);
      h2 {
        font-weight: bold;
        color: rgba(80, 30, 14, 1);
        font-family: syne-extrabold, sans-serif;
        font-size: 46px;
        text-align: center;
      }
    }
  }
}
