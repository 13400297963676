/* Galeria.css */
.galeria-flex {
  display: flex;
  flex-wrap: wrap;
  margin: 5%;
  gap: 20px;
  justify-content: center;
  align-items: center;
  .imagen {
    width: calc(33% - 10px);
    display: flex;
    img {
      object-fit: contain;
      width: 100%;
    }
  }
}
@media screen and (max-width: 992px) {
  .galeria-flex {
    gap: 10px;
  }
}
@media screen and (max-width: 576px) {
  .galeria-flex {
    .imagen {
      width: calc(50% - 10px);
    }
  }
}
