@font-face {
  font-family: 'STYLU';
  src: url('../public/STYLU.TTF');
}

/* html, body, * {
  font-family: 'STYLU' !important;
}

body {
  font-family: 'STYLU' !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
} */

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}
/* 
main {
  /* padding: 5% !important; */
/* padding: '20px'; */
/* margin: '20px'; */
/* } */
