.objetive-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 10px;
  .description-final {
    width: 50%;
    height: 100%;
    padding: 20px;
    background-color: rgba(255, 222, 95, 0.45);
    text-align: center;
    h2 {
      font-weight: bold;
      color: rgba(80, 30, 14, 1);
    }
    p {
      font-weight: 400;
      font-size: 18px;
      text-align: justify;
    }
  }
  .image-container-description {
    width: 50%;
    height: 100%;
    img {
      width: 100%;
      max-height: 80vh;
      object-fit: contain;
    }
  }
}

@media screen and (max-width: 992px) {
  .objetive-container {
    padding: 1%;
    justify-content: center;
    align-items: center;
    .description-final {
      h2 {
        font-size: 40px;
      }
      p {
        font-size: 20px;
      }
    }
    .image-container-description {
    }
  }
}
@media screen and (max-width: 576px) {
  .objetive-container {
    flex-direction: column;
    padding: 0;
    justify-content: center;
    align-items: center;
    .description-final {
      width: 90%;
      p {
        padding-left: 20px;
      }
    }
    .image-container-description {
      width: 90%;
      padding-left: 10px;
      padding-right: 10px;
    }
  }
}
