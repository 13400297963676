.element-carousel {
  width: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  .container-image {
    width: 94%;
    border: 1px solid gray;
    img {
      width: 100%;
      height: auto;
      object-fit: contain;
    }
  }

  .slick-slider {
    position: relative;
    width: 80%;
    margin: 0 auto;
  }
}

.custom-prev-arrow,
.custom-next-arrow {
  font-size: 24px;
  color: transparent;
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.arrow-background {
  background-color: rgba(29, 14, 3, 0.8);
  border-radius: 50%;
  padding: 10px;
}

.custom-prev-arrow {
  left: 10px;
}

.custom-next-arrow {
  right: 10px;
}
