.gallery-flex-container {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
  padding: 10px;
  justify-content: center;

  .gallery-item {
    width: calc(25% - 16px); /* Adjust the width based on the number of columns you want */
    cursor: pointer;
    overflow: hidden;
    position: relative;
    transition: transform 0.3s ease;
    display: flex;
    img,
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
      display: block;
    }

    &:hover {
      transform: scale(1.4);
      z-index: 30;
    }
  }
}
@media screen and (max-width: 992px) {
  .gallery-flex-container {
    .gallery-item {
      width: calc(33% - 10px);
      &:hover {
        transform: scale(1.25);
      }
    }
  }
}
@media screen and (max-width: 576px) {
  .gallery-flex-container {
    .gallery-item {
      width: calc(50% - 10px);
      &:hover {
        transform: scale(1.1);
      }
    }
  }
}
