/* ImageViewer.scss */

.image-viewer-overlay {
  position: fixed;
  z-index: 2000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: #faebe1;
  display: flex;
  justify-content: center;
  align-items: center;
  .image-video-container {
    width: 40vw;
    height: 90vh;
    display: flex;
    video,
    img {
      width: 100%;
      max-height: 100%;
      object-fit: contain;
    }
  }

  button {
    position: absolute;
    top: 15px;
    background: none;
    border: none;
    color: black;
    cursor: pointer;
    font-size: 40px;
    cursor: pointer;
    &.close-button {
      right: 15px;
    }

    &.previous-button {
      left: 15px;
      top: 50%;
      transform: translate(0%, -50%);
    }

    &.next-button {
      right: 15px;
      top: 50%;
      transform: translate(0%, -50%);
    }
  }
}
