/* Contact.css */
.whatsapp-container {
  position: relative;
}

.floating-button {
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: rgb(1, 104, 85);
  color: white;
  padding: 20px;
  z-index: 9999;
  border-radius: 50px;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.icon {
  font-size: 30px;
}

.text {
  font-weight: bold;
  font-size: 20px;
  margin-left: 5px;
}
